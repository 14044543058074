import moment from 'moment';
import { Children } from 'react/cjs/react.production.min';
import { fetchTracking } from './fetchApi';


export const searchData = (identifier, shell) =>{
    if (shell){
        for (const data of shell?.['shellDatas']) {
            if (data?.attribute?.["identifier"] === identifier) {
                return data.value
            }
        }
    }

    return null
}

export const sortByDate = (shells,identifier,orderBy = "ASC") => {
    
    if(shells.length > 1){
        shells.sort(function(a,b){
            let dateA = moment(a.date_saisie);
            let dateB = moment(b.date_saisie);

            return orderBy === "ASC" ? dateA - dateB : dateB - dateA
        })
    }

    var temps = [];

    try {
        shells.forEach(shell => {
            if(!temps.some(e => (
                e.code_activite === shell.code_activite 
                && e.code_evt === shell.code_evt
            ))) {
                temps.push(shell)
            }
        })
    } catch (error) {
        return shells;
    }

    return temps;
}

export const mapShell = (shell) =>{

    let mappedShellDatas = {};
    let mappedChildrens = shell.children ? {} : null;
    for(let shellData of shell.shellDatas){
        mappedShellDatas[shellData.attribute.identifier] = {value : shellData.value, attribute_type : shellData.attribute.attribute_type,id:shellData.id}
    }
    for(let children of shell.children){
        mappedChildrens[children.eav_type] = mapShell(children)
    }

    return({id:shell.id,children : mappedChildrens,...mappedShellDatas})
    
}

export const prepareSearch = (searchVar) => {
    let keys = Object.keys(searchVar);
    let queryVar = [];
    for(let key of keys){
        queryVar.push({
            identifier : key,
            value : searchVar[key],
            locale : '',
            option : '',
        })
    }

    return queryVar;
}

export const prepareFilters = (searchVar) => {
    let keys = Object.keys(searchVar);
    let queryVar = [];
    for (let key of keys) {
        if (searchVar[key].input) {
            queryVar.push({
            identifier : key,
            value : searchVar[key].input,
            locale : '',
            option : '',
        }) 
        }
    }

    return queryVar;
}

export const getTracking = (id) => {
    return new Promise(async(resolve)=>{
        //TODO DYNAMIC PARENTS VARIABLE
        let parents = [];
        if(localStorage.getItem('AUTH_EMAIL') === 'ccp@gmail.com'){
            parents.push({"eav_type": "CLIENT", "shellDatas": [{"identifier": "client_code", "value": "CCP"}]});
        }
        else if(localStorage.getItem('AUTH_EMAIL') === 'cote@gmail.com'){
            parents.push({"eav_type": "CLIENT", "shellDatas": [{"identifier": "client_code", "value": "COTE"}]});
        }
        // let variables = {
        //     shellDatas: [
        //         {
        //             identifier : type,
        //             locale:"",
        //             value : id,
        //             option: ""                        
        //         },
        //     ],
        //     parents
        // }

        // let tracking = await fetchShell('REPORT',variables);
        let getData = await fetchTracking(id);
        resolve(getData?.reports);
    })
}


export const createStatusTracking = async (order, statusTracking, setTypeOrder, setStatusTracking, setIsLoaded) => {
        let idTrans = order.urlTracking;
        let trackings = [];
        let transTrack = [];
        if (idTrans){
            transTrack = await getTracking(idTrans);
        }
        
        trackings = [...trackings, ...transTrack]
        
        const type_cmd_log = order?.sttiFlatInformation?.infoQuai;
        let getStatus = statusTracking;
        if (type_cmd_log === "MES") {
            setTypeOrder('mes')
        } else if (type_cmd_log == "LAD" || type_cmd_log == "SMA") {
            setTypeOrder('lad-sma')
        } else {
            setTypeOrder('trans')
        }

        for (let track of trackings) {
            let getEVT = track.code_evt
            let typeEVT = track.type_evt
            if (getEVT) {
                if (getEVT === "CRE") {
                    getStatus.prise_compte.status = true
                    getStatus.prise_compte.date = track.date_situation
                    getStatus.prise_compte.date_saisie = track.date_saisie
                } else if (getEVT === "PRE") {
                    getStatus.prep.status = true
                    getStatus.prep.date = track.date_situation
                    getStatus.prep.date_saisie = track.date_saisie
                } else if (getEVT === "DIF" && typeEVT === "TRANS") {
                    getStatus.rdv.status = true
                    getStatus.rdv.date = track.date_situation
                    getStatus.rdv.date_saisie = track.date_saisie
                    getStatus.rdv.refused = track.code_justification === 'DAF'
                } else if (getEVT === "VAL"&& typeEVT === "TRANS") {
                    getStatus.expedition.status = true
                    getStatus.expedition.date = track.date_situation
                    getStatus.expedition.date_saisie = track.date_saisie
                }
                else if (getEVT === "LIV") {
                    getStatus.livraison.status = true
                    getStatus.livraison.date = track.date_situation
                    getStatus.livraison.date_saisie = track.date_saisie
                } else if (getEVT === "AAR") {
                    getStatus.aar.status = true
                    getStatus.aar.date = track.date_situation
                    getStatus.aar.date_saisie = track.date_saisie
                }
            }
        }

        setStatusTracking(getStatus);
        setIsLoaded(true)
    }